<template>
  <div>
    <NuxtLoadingIndicator />
    <Teleport to="body">
      <LoadersClearLoader v-model="pending"></LoadersClearLoader>
    </Teleport>
    <NuxtErrorBoundary>
      <template #error="{ error }">
        {{ error }} default
        <ErrorBoundary :error="error" />
      </template>
      <NuxtPage v-if="online" />
      <OfflinePage v-else />
    </NuxtErrorBoundary>
  </div>
</template>
<script setup>
import { useOnline } from '@vueuse/core';
import useClearFetchStore from '@/stores/useClearFetchStore';
import { storeToRefs } from 'pinia';
import getLogOutChannel from '@/services/getLogOutChannel';

const online = useOnline();
const fetchObj = useClearFetchStore();
const { pending } = storeToRefs(fetchObj);
const channel = getLogOutChannel();
channel.addEventListener('message', (e) => {
  if (e.data?.isLogOut) {
    window.location.reload();
  }
});
</script>
