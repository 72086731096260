<template>
  <div class="flex flex-row min-h-screen">
    <LoginTheLeftLogoArea></LoginTheLeftLogoArea>
    <div class="w-screen md:w-2/5 relative">
      <div class="w-full flex flex-col justify-center items-center h-full">
        <ElementsLogoClearLogo class="h-10 mb-10" />
        <span class="text-red-500 text-2xl 2xl:text-3xl">
          {{ $t('offline') }}
        </span>
      </div>
    </div>
  </div>
</template>
